#github-corner:hover .octo-arm {
  animation: octocat-wave 560ms ease-in-out;
}
@keyframes octocat-wave {
  0%,
  100% {
    transform: rotate(0);
  }
  20%,
  60% {
    transform: rotate(-25deg);
  }
  40%,
  80% {
    transform: rotate(10deg);
  }
}
@media (max-width: 500px) {
  #github-corner:hover .octo-arm {
    animation: none;
  }
  #github-corner .octo-arm {
    animation: octocat-wave 560ms ease-in-out;
  }
}

#small-logos-container {
  position: absolute;
  right: 10px;
  top: 9px;
}

#vscode-logo {
  width: 29px;
  filter: brightness(0) saturate(100%) invert(99%) sepia(99%) saturate(0%) hue-rotate(297deg) brightness(101%) contrast(103%);
}

#vscode-logo:hover {
  filter: none;
}

#github-logo {
  width: 24px;
  margin-left: 8px;
}

#github-logo:hover {
  filter: brightness(0) saturate(100%) invert(0%) sepia(95%) saturate(7499%) hue-rotate(237deg) brightness(96%) contrast(100%);
}

.logo {
  cursor: pointer;
}

/* when screen width is small - make icons smaller, reduce distance between them and move them closer to top rigt of screen */
@media (max-width: 420px) {
  #small-logos-container {
    top: 4px;
    right: 3px;
  }
  #vscode-logo {
    width: 25px;
  }
  #github-logo {
    width: 20px;
    margin-left: 1px;
  }
}
