#invert-button {
  color: rgb(92, 92, 92);
  background-color: #fff;
  border-color: #ccc;
  padding: 6px 13px;
  font-size: 17px;
  line-height: 1.3333333;
  border-radius: 6px;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#invert-button:hover {
  color: rgb(27, 27, 27);
  background-color: #f5f5e8;
}

#invert-button:active {
  color: rgb(27, 27, 27);
  background-color: #d4d4d4;
}

/* when screen width is small - move the button lower */
@media (max-width: 860px) {
  #invert-button {
    top: 52%;
  }
}

/* when screen width is small - move the button lower */
@media (max-width: 860px) and (max-height: 330px) {
  #invert-button {
    top: 54%;
  }
}

/* when screen width is small - move the button lower */
@media (max-width: 860px) and (max-height: 290px) {
  #invert-button {
    top: 55%;
  }
}

/* when screen width is small - move the button lower */
@media (max-width: 860px) and (max-height: 270px) {
  #invert-button {
    top: 56%;
  }
}

/* when screen width is small - move the button lower */
@media (max-width: 860px) and (max-height: 251px) {
  #invert-button {
    top: 57%;
  }
}

/* when screen width is small - move the button lower */
@media (max-width: 860px) and (max-height: 220px) {
  #invert-button {
    top: 58%;
  }
}
