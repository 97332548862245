#title {
  left: 50%;
  color: white;
  font-size: 34px;
  margin-top: 3.6vh;
  position: absolute;
  white-space: nowrap;
  pointer-events: none;
  transform: translateX(-50%);
  font-family: 'Inter', sans-serif;
}

/* when screen height is small - move the title higher and make the font smaller */
@media (max-height: 700px) {
  #title {
    margin-top: 17px;
    font-size: 32px;
  }
}

/* when screen height is small - move the title higher and make the font smaller */
@media (max-height: 500px) {
  #title {
    margin-top: 14px;
    font-size: 30px;
  }
}

/* when screen height is small - move the title higher */
@media (max-height: 400px) {
  #title {
    margin-top: 10px;
  }
}
